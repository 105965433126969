<template>
  <AppDrawer v-model:open="open" :headline="$t('Notifications')">
    <div v-if="!loading" class="flex-grow flex flex-col justify-between">
      <div>
        <div class="grid grid-cols-1 divide-y divide-gray-200 flex-grow">
          <div
            v-for="notification in notifications.data"
            :key="notification.id"
            :class="[
              'flex py-4 cursor-pointer text-sm',
              notification.attributes.read_at
                ? 'text-gray-700'
                : 'text-orange-700',
            ]"
            @click="navigate(notification)"
          >
            <div class="pl-2 pr-4">
              <PrinterIcon
                v-if="notification.attributes.category === 'print-job-failed'"
                class="h-8 w-8"
              />

              <CalendarIcon
                v-if="
                  notification.attributes.category ===
                  'late-reservation-created'
                "
                class="h-8 w-8"
              />
            </div>

            <div class="flex-grow">
              <div
                v-if="notification.attributes.category === 'print-job-failed'"
              >
                {{
                  $t("A print job for {printer} failed.", {
                    printer: notification.attributes.data.printer_name,
                  })
                }}
              </div>

              <div
                v-if="
                  notification.attributes.category ===
                  'late-reservation-created'
                "
              >
                {{
                  $t("A late reservation has been created for {name}.", {
                    name: notification.attributes.data.reservation_name,
                  })
                }}
              </div>

              <div class="text-gray-400 mt-1">
                {{ useDateTime(notification.attributes.created_at) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="border-t-2 border-gray-300 pt-4 grid grid-cols-2 gap-4">
        <AppButton
          type="button"
          background-color-class="bg-sky-600"
          :icon-before="BackwardIcon"
          :disabled="notifications.meta.current_page === 1"
          @click="page = page - 1"
        >
          {{ $t("Previous") }}
        </AppButton>

        <AppButton
          type="submit"
          background-color-class="bg-sky-600"
          :icon-after="ForwardIcon"
          :disabled="
            notifications.meta.current_page === notifications.meta.last_page
          "
          @click="page = page + 1"
        >
          {{ $t("Next") }}
        </AppButton>
      </div>
    </div>
  </AppDrawer>
</template>

<script setup>
import {
  BackwardIcon,
  CalendarIcon,
  ForwardIcon,
  PrinterIcon,
} from "@heroicons/vue/24/outline/index";
import { useAuth } from "~/stores/auth.js";

const open = defineModel("open", {
  required: true,
  type: Boolean,
});

const auth = useAuth();
const notifications = ref(null);
const loading = ref(true);
const page = ref(1);

const load = async () => {
  notifications.value = await useApi("/notifications", {
    query: { "page[number]": page.value, "page[size]": 5 },
  });

  auth.resetUnreadNotifications();

  loading.value = false;
};

const navigate = async (notification) => {
  switch (notification.attributes.category) {
    case "print-job-failed":
      await navigateTo("/print-jobs");
      break;
    case "late-reservation-created":
      await navigateTo("/reservations");
  }

  open.value = false;
};

watch(open, async () => {
  await load();
});

watch(page, async () => {
  await load();
});
</script>
